export const menu = [
    {
        "type": 'header',
        "href": '#',
        "icon": '',
        "entity": '',
        "label": 'Pagine'
    },
    {
        "type": 'item',
        "href": '/',
        "icon": 'home',
        "entity": '',
        "label": 'Dashboard'
    },
    {
        "type": 'item',
        "href": '/entity/event',
        "icon": 'calendar',
        "entity": 'event',
        "label": 'Eventi'
    },
    {
        "type": 'item',
        "href": '/entity/publication',
        "icon": 'book',
        "entity": 'publication',
        "label": 'Pubblicazioni'
    },
    {
        "type": 'item',
        "href": '/entity/association',
        "icon": 'globe',
        "entity": 'association',
        "label": 'Associazioni'
    },
    {
        "type": 'item',
        "href": '/entity/certificate_template',
        "icon": 'file',
        "entity": 'certificate_template',
        "label": 'Template certificati'
    },
    {
        "type": 'header',
        "label": 'Corsi'
    },
    {
        "type": 'item',
        "href": '/entity/category',
        "icon": 'tag',
        "entity": 'category',
        "label": 'Categorie'
    },
/*     {
        "type": 'item',
        "href": '/entity/path',
        "icon": 'folder',
        "entity": 'path',
        "label": 'Percorsi'
    }, */
    {
        "type": 'item',
        "href": '/entity/course',
        "icon": 'graduation-cap',
        "entity": 'course',
        "label": 'Corsi'
    },
    {
        "type": 'header',
        "href": '#',
        "icon": '',
        "entity": '',
        "label": 'Utente'
    },
    {
        "type": 'item',
        "href": '/settings',
        "icon": 'user-gear',
        "entity": '',
        "label": 'Profilo'
    },
    {
        "type": 'folder',
        "href": '#',
        "icon": 'cogs',
        "entity": '',
        "label": 'Impostazioni',
        "items": [
            {
                "type": 'item',
                "href": '/entity/user',
                "entity": 'user',
                "label": 'Utenti'
            },
            {
                "type": 'item',
                "href": '/entity/role',
                "entity": 'role',
                "label": 'Ruoli'
            },
            {
                "type": 'item',
                "href": '/entity/user_group',
                "entity": 'user_group',
                "label": 'Gruppi'
            },
            {
                "type": 'item',
                "href": '/entity/user_entity_saved_filters',
                "entity": 'user_entity_saved_filters',
                "label": 'Filtri salvati'
            },
            {
                "type": 'item',
                "href": '/entity/user_entity_preferences',
                "entity": 'user_entity_preferences',
                "label": 'Preferenze entità'
            },
        ]
    },
    {
        "type": 'item',
        "href": '/logout',
        "icon": 'arrow-right-from-bracket',
        "entity": '',
        "label": 'Logout'
    }
]